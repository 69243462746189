import styled from "styled-components"

const ResponsiveImg = styled.img`
  display: block;

  margin: 0 auto;
  @media (min-width: 600px) {
    float: right;
    padding: 0px 0px 0px 8px;
  }
`
export default ResponsiveImg
