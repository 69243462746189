import { createMuiTheme } from "@material-ui/core/styles"
import blue from "@material-ui/core/colors/blue"

const theme = createMuiTheme({
  spacing: 8,
  palette: {
    text: {
      primary: "#1a1a1a",
    },
    primary: {
      main: "#0734ff",
      light: "#0734ff",
      dark: "#0734ff",
    },
    secondary: blue,
  },
  overrides: {
    // Style sheet name ⚛️
    MuiTypography: {
      // Name of the rule
      body1: {
        // Some CSS
        fontSize: "18px",
      },
    },
  },
})

export default theme
