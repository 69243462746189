import React from "react"

import styled, { ThemeProvider, createGlobalStyle } from "styled-components"
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core"
import theme from "./src/theme"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import { MDXProvider } from "@mdx-js/react"

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }
  `
const Header1 = props => <Typography gutterBottom variant="h3" {...props} />
const Header2 = props => <Typography gutterBottom variant="h4" {...props} />
const Header3 = props => <Typography gutterBottom variant="h5" {...props} />
const Header4 = props => <Typography gutterBottom variant="h6" {...props} />
const Paragraph = props => <Typography paragraph {...props} />
const LinkText = props => <Link variant="body1" {...props} />

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const components = {
  h1: Header1,
  h2: Header2,
  h3: Header3,
  h4: Header4,
  p: Paragraph,
  a: LinkText,
  img: Image,
  li: props => (
    <li>
      <Paragraph {...props} />
    </li>
  ),
}

export const wrapRootElement = ({ element }) => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <MDXProvider components={components}>{element}</MDXProvider>
    </ThemeProvider>
  </MuiThemeProvider>
)
